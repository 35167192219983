<template>
  <div>
<!--    <div class="header">-->
<!--       <van-tabs color="#004ea3">-->
<!--        <van-tab :title="$t('trade.title')"></van-tab>-->
<!--       <van-tab>-->
<!--        <template #title>-->
<!--            <span @click="tofabi">{{ $t('trade.title3') }}</span>-->
<!--          </template>-->
<!--        </van-tab>-->
<!--          <van-tab>-->
<!--        <template #title>-->
<!--            <span @click="tofund">DeFi</span>-->
<!--          </template>-->
<!--        </van-tab>-->
<!--        <van-tab></van-tab>-->
<!--        <van-tab></van-tab>-->
<!--        <van-tab></van-tab>-->
<!--      </van-tabs>-->
<!--    </div>-->
    <div class="maincontent">
      <!-- 产品 -->
      <van-row class="name-icon">
        <van-image
          width="1.2rem"
          height="1.2rem"
          :src="require('../../assets/img/trade.png')"
          @click="open"
        ></van-image>
        <!-- 货币行情 弹层 -->
        <van-popup
          v-model="isshow"
          position="left"
          :style="{ height: '100%', width: '75%' }"
        >
          <van-row class="personage-head">
            <h3>{{ $t('trade.hbhq') }}</h3>
          </van-row>
          <van-tabs color="#004ea3">
            <!-- 自选 -->
            <van-tab :title="$t('trade.zixuan')">
              <van-row
                class="popup-Increase"
                @click="changeproduct(item.from, item.productid)"
                v-for="item in zixuanlist"
                :key="item.id"
              >
                <van-col :span="12">
                  <span style="text-align:left">{{ item.product_title }}</span>
                </van-col>
                <van-col :span="12" style="text-align:right">
                  <b>{{ item.current }}</b>
                </van-col>
              </van-row>
            </van-tab>
            <!-- 产品 -->
            <!-- <van-tab title="USDT">
              <van-row
                class="popup-Increase"
                @click="changeproduct(item.from, item.pid)"
                v-for="item in productlist"
                :key="item.id"
                v-if="item.product_type_id==1"
              >
                <van-col :span="12">
                  <span style="text-align:left">{{ item.product_title }}</span>
                </van-col>
                <van-col :span="12" style="text-align:right">
                  <b>{{ item.current }}</b>
                </van-col>
              </van-row>
            </van-tab> -->
            <!-- 外汇 -->
            <van-tab :title="$t('trade.title3')">
              <van-row
                class="popup-Increase"
                @click="changeproduct(item.from, item.pid)"
                v-for="item in productlist"
                :key="item.id"
                v-if="item.product_type_id==2"
              >
                <van-col :span="12">
                  <span style="text-align:left">{{ item.product_title }}</span>
                </van-col>
                <van-col :span="12" style="text-align:right">
                  <b>{{ item.current }}</b>
                </van-col>
              </van-row>
            </van-tab>
          </van-tabs>
        </van-popup>
        <!-- 弹层 -->
        <span style="margin: 0px 5px;">{{ detail.product_title }}</span>
        <van-button
          size="small"
          :style="[
            { background: pagedata.change > 0 ? '#85B480' : '#CD4F64' },
            { borderColor: pagedata.change > 0 ? '#85B480' : '#CD4F64' },
            { color: 'white' }
          ]"
        >
          {{ pagedata.change }}%
        </van-button>
      </van-row>
      <!-- 价格 -->
      <van-row class="total">
        <van-row class="head">
          <van-col :span="12">
            <van-row class="head-now">
              <van-col :span="24">
                <span style='color: #b2b2b2;'>{{ $t('home.price') }}</span>
                <h1
                  :style="{
                    color: pagedata.change > 0 ? '#85B480' : '#CD4F64'
                  }"
                >
                  {{ pagedata.current }}
                </h1>
<!--                <span>≈{{ pagedata.cny }} CNY</span>-->
                <span style='color: #b2b2b2;'>{{ $t('home.updown') }}</span>
                <span>

                  <span
                    :style="{
                      color: pagedata.change > 0 ? '#85B480' : '#CD4F64'
                    }"
                    >{{ pagedata.change }}%</span
                  >
                </span>
              </van-col>
            </van-row>
          </van-col>
          <van-col :span="12">
            <van-row class="head-history">
              <van-row class="head-extreme">
                <div>
                  <span>{{ $t('trade.high') }}</span>
                </div>
                <div>
                  <span>{{ $t('trade.low') }}</span>
                </div>
                <div><span>24h</span></div>
              </van-row>
              <van-row>
                <div><span>{{tradeData.high}}</span></div>
                <div><span>{{tradeData.low}}</span></div>
                <div><span>{{tradeData.volume}}</span></div>
              </van-row>
            </van-row>
          </van-col>
        </van-row>
      </van-row>
      <!-- k图 -->
      <div>
        <echarts :type="type" :currentprice="currentprice" :productid="productid" />
      </div>
      <div
        data-v-0386fab6=""
        style="background-color: rgb(248, 248, 248); height: 10px;"
      ></div>
      <!-- 订单列表 -->
      <van-tabs class="order-List" color="#004ea3">
        <!-- 委托订单 -->
<!--        <van-tab :title="$t('trade.weit')">-->
<!--          <van-row class="noDate" v-if="progressorder.length == 0">-->
<!--            <van-empty :description="$t('common.nomore')" />-->
<!--          </van-row>-->
<!--          <van-row v-else>-->
<!--            <van-row-->
<!--              class="currency-Increase"-->
<!--              v-for="item in progressorder"-->
<!--              :key="item.id"-->
<!--            >-->
<!--              <van-col :span="8" style="text-align:left">-->
<!--                <span>-->
<!--                  <span v-if="item.type == '1'" style="color:#04AC92">-->
<!--                    {{ $t('trade.more') }}-->
<!--                  </span>-->
<!--                  <span v-else style="color:#CD4F64">-->
<!--                    {{ $t('trade.empty') }}-->
<!--                  </span>-->
<!--                  <br />-->
<!--                  <h5-->
<!--                    style="color: rgb(128, 128, 128); margin-top: 10px; font-size: 16px;"-->
<!--                  >-->
<!--                    {{ item.product_name }}-->
<!--                  </h5>-->
<!--                </span>-->
<!--              </van-col>-->
<!--              <van-col :span="16" class="currency-time">-->
<!--                <span>-->
<!--                  <span-->
<!--                    >{{ $t('trade.amount') }}<br />-->
<!--                    {{ item.total_price }}</span-->
<!--                  >-->
<!--                </span>-->
<!--                <br />-->
<!--                <span>-->
<!--                  {{ $t('trade.jian') }} <br />-->
<!--                  {{ item.open_price }}-->
<!--                </span>-->
<!--                <br />-->
<!--                <span>-->
<!--                  {{ item.createtime | dateformat }}-->
<!--                </span>-->
<!--              </van-col>-->
<!--            </van-row>-->
<!--            <van-row style="margin: 20px;">{{ $t('common.nomore') }}</van-row>-->
<!--          </van-row>-->
<!--        </van-tab>-->
        <!-- 成交订单 -->
        <van-tab :title="$t('trade.deal')">
          <van-row class="noDate" v-if="completedorder.length == 0">
            <van-empty :description="$t('common.nomore')" />
          </van-row>
          <van-row v-else>
            <van-row
              class="currency-Increase"
              v-for="item in completedorder"
              :key="item.id"
            >
              <van-col :span="8" style="text-align:left">
                <span style="color: rgb(205, 79, 100);">
                  <span v-if="item.type == '1'" style="color:#04AC92">
                    {{ $t('trade.more') }}
                    <van-image
                      width="1.5rem"
                      height="1rem"
                      :src="require('../../assets/img/jtk.png')"
                    />
                  </span>
                  <span v-else style="color:#CD4F64">
                    {{ $t('trade.empty') }}
                    <van-image
                      width="1.5rem"
                      height="1rem"
                      :src="require('../../assets/img/jtd.png')"
                    />
                  </span>
                  <br />
                  <h5
                    style="color: rgb(128, 128, 128); margin-top: 10px; font-size: 16px;"
                  >
                    {{ item.product_name }}
                  </h5>
                </span>
              </van-col>
              <van-col :span="8">
                <span style="color: rgb(205, 79, 100);">{{ item.profit }}</span>
              </van-col>
              <van-col :span="8" class="currency-time">
                <span>
                  <span>
                    {{ $t('trade.amount') }}<br />
                    {{ item.total_price }}
                  </span>
                </span>
                <br />
                <span>
                  {{ $t('trade.jian') }} <br />
                  {{ item.open_price }}
                </span>
                <br />
                <span>
                  {{ $t('trade.ping') }} <br />
                  {{ item.end_price }}
                </span>
              </van-col>
            </van-row>
            <van-row style="margin: 20px;">{{ $t('common.nomore') }}</van-row>
          </van-row>
        </van-tab>
        <!-- 产品简介 -->
        <van-tab :title="$t('trade.desc')">
          <van-row class="brief">
            <van-row class="brief-publish">
              <h3>{{ detail.product_title }}</h3>
            </van-row>
            <van-row class="brief-publish">
              <van-col :span="12" style="text-align: left;">
                <span>{{ $t('trade.time') }}</span>
              </van-col>
              <van-col :span="12" style="text-align: right;"
                ><span>{{ detail.publishtime }}</span>
              </van-col>
            </van-row>
            <van-row class="brief-publish">
              <van-col :span="12" style="text-align: left;">
                <span>{{ $t('trade.number') }}</span>
              </van-col>
              <van-col :span="12" style="text-align: right;"
                ><span>{{ detail.publishnum }}</span>
              </van-col>
            </van-row>
            <van-row class="brief-publish" style="border-bottom:none">
              <h3>{{ $t('trade.desc') }}</h3>
              <span v-if="lang === 'en'">
                {{ detail.en_description }}
              </span>
              <span v-else>
                {{ detail.cn_description }}
              </span>
            </van-row>
          </van-row>
        </van-tab>
      </van-tabs>
      <!-- 按钮组 -->
      <van-row class="operation">
<!--        <van-col :span="8" style="padding-left:15px">-->
<!--          <van-checkbox-->
<!--            v-model="ischecked"-->
<!--            icon-size="15px"-->
<!--            @click="changechecked"-->
<!--            >{{ $t('trade.addbtn') }}</van-checkbox-->
<!--          >-->
<!--        </van-col>-->
        <van-col :span="12">
          <van-button
            round
            type="info"
            style="color: white; background: rgb(4, 172, 146); border-color: rgb(4, 172, 146);"
            @click="tradeopen(1)"
            >{{ $t('trade.long') }}</van-button
          >
        </van-col>
        <van-col :span="12">
          <van-button
            round
            type="default"
            style="color: white; background: rgb(205, 79, 100); border-color: rgb(205, 79, 100);"
            @click="tradeopen(2)"
            >{{ $t('trade.short') }}</van-button
          >
        </van-col>
        <!-- 买多 卖空 弹出层 -->
        <van-popup
          v-model="tradeshow"
          closeable
          class="operation-buyUp"
          @close="tradestep = 1"
        >
          <van-row v-if="tradestep === 1">
            <h3 style='font-size: 1rem'>{{ $t('trade.confirm') }}</h3>
            <van-row class="text">
              <van-row style="height:90%">
                <van-cell
                  :title="$t('trade.name')"
                  :value="detail.product_title_title"
                />
                <van-cell
                  :title="$t('trade.fangx')"
                  :value="
                    tradedata.type === 1 ? $t('trade.long') : $t('trade.short')
                  "
                />
                <van-cell :title="$t('trade.price')" :value="tradedata.price" />
                <span style="color: rgb(128, 128, 128);font-size: 0.8rem">{{
                  $t('trade.selecttime')
                }}</span>
                <van-row class="currency-information">
                  <div
                    class="information"
                    v-for="(item, index) in detail.times"
                    :key="index"
                    @click="choose(index)"
                    :class="isactive === index ? 'bg' : ''"
                  >
                   <span style="color: white; display: block;font-size: 0.5rem">{{ $t('trade.settletime') }}</span
                                       >
                    <span style="color: white; display: block;"
                      >{{ item }}M</span
                    >
                    <span style="color: white;font-size: 0.5rem"">{{ $t('trade.profit') }}:{{ detail.ratio[index] }}%</span>
                  </div>
                </van-row>
                <span style="color: rgb(128, 128, 128);font-size: 0.8rem">{{
                  $t('trade.touzje')
                }}</span>
                <van-row class="money-information">
                  <div
                    class="moneynums"
                    v-for="(item, index) in moneys"
                    :key="index"
                    @click="choosemoney(index)"
                    :class="isactives === index ? 'bgs' : ''"
                  >
                    <span style="color: white;font-size: 0.5rem">{{ currencysysm }} {{ moneys[index] }}</span>
                  </div>
                </van-row>
                <div style="position: relative">
                   <span  style="position: absolute;width: 10%;top: 15%;left: 5px;color: #424c66;padding: 2px 1px;font-size: 1rem" >
                    {{ currencysysm }}
                  </span>
                  <van-field
                    v-model="tradedata.total"
                    :placeholder="$t('trade.input')"
                    class="qbye"
                  />
                  <span @click="quanbuye()" style="position: absolute;width: 24%;background: #424c66;top: 15%;right: 0.6rem;color: white;padding: 3px 2px;font-size: 0.7rem" >
                    {{$t('trade.qbyeee')}}
                  </span>
                </div>
                <div style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;">
                  <span style="font-size: 0.6rem;margin-right: 1.5rem">{{ $t('trade.usable') }}:{{ tradedata.balance*currencyhuilv | priceformat}}</span>
                  <span style="font-size: 0.6rem;" v-if="detail.minimum">{{ $t('trade.min') }}:{{
                      detail.minimum[this.isactive]
                    }}</span>
                </div>
<!--                <div-->
<!--                  style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;"-->
<!--                >-->
<!--                  <span style="font-size: 0.8rem;">{{ $t('trade.usable') }}:{{ tradedata.balance }}({{ currycurrent }})</span>-->
<!--                </div>-->
<!--                <div-->
<!--                  style="width: 100%; text-align: left; padding-left: 13px; margin: 10px 0px;"-->
<!--                >-->
<!--                  <span>{{ $t('trade.fee') }}:{{ tradedata.fee }}%</span>-->
<!--                </div>-->
              </van-row>
              <van-button @click="beforsubmit">{{
                $t('common.confirm')
              }}</van-button>
            </van-row>
          </van-row>
          <!-- 倒计时页面 -->
          <van-row v-else style="height:100%">
            <h3>{{ $t('trade.confirm') }}</h3>
            <van-row class="text">
              <van-row style="height:90%">
                <van-row type="flex" justify="center">
                  <van-count-down
                    :time="this.tradedata.times * 60000"
                    class="count-down"
                    format="ss"
                    @finish="finish"
                  />
                </van-row>
                <van-cell
                  :title="$t('trade.fangx')"
                  :value="
                    tradedata.type === 1 ? $t('trade.long') : $t('trade.short')
                  "
                />
                <van-cell
                  :title="$t('trade.touzje')"
                  :value="tradedata.total"
                />
                <van-cell :title="$t('trade.price')" :value="tradedata.price" />
                <van-cell
                  :title="$t('trade.yuqi')"
                  :value="(tradedata.total * tradedata.ratio) / 100"
                />
                <van-cell
                  :title="$t('trade.fee')"
                  :value="tradedata.fee"
                />
              </van-row>
<!--              <van-button @click="submit">{{ $t('trade.confirm') }}</van-button>-->
            </van-row>
          </van-row>
        </van-popup>
        <!-- 买多 卖空 弹出层 -->
      </van-row>
    </div>
  </div>
</template>
<script>
import echarts from '@/components/echarts.vue'
export default {
  components: { echarts },
  data() {
    return {
      tradeData: {},
      // websorket
      websocket: {},
      // 弹出层显示
      isshow: false,
      // 弹窗的数据
      productlist: [],
      zixuanlist: [],
      currycurrent: localStorage.getItem('currency') || 'USDT',
      currencysysm: '$',
      currencyhuilv: 1,
      // 自选
      ischecked: false,
      // 产品
      type: this.$route.params.id || 'btc',
      productid: 1,
      // 页面数据
      pagedata: {},
      // 产品详情
      detail: {},
      moneys: ['200', '2000', '5000', '10000'],
      // 当前价格
      currentprice: '',
      // 完成的订单列表
      completedorder: [],
      // 进行中的订单列表
      progressorder: [],
      // 买多买空弹出层
      tradeshow: false,
      tradeLoading: false,
      // 确认步骤
      tradestep: 1,
      feeradio: 1,
      // 选择标识
      isactive: null,
      isactives: null,
      // 买多买空数据对象
      tradedata: {
        type: 1, // 买多，买空
        price: 10, // 当前价格
        fee: 0, // 手续费
        balance: 0 // 可用余额
      },
      // 请求订单列表定时器
      tradetimer: '',
      lang: localStorage.getItem('lang') || 'zh'
    }
  },

  created() {
    this.productid = this.$route.params.id ? this.$route.params.id : 1
    switch (this.productid) {
	  case '22':
        this.type = 'link'
        break
	  case '21':
        this.type = 'bsv'
        break
	  case '20':
        this.type = 'luna'
        break
      case '8':
        this.type = 'neo'
        break
      case '7':
        this.type = 'zec'
        break
      case '5':
        this.type = 'etc'
        break
      case '6':
        this.type = 'xrp'
        break
      case '2':
        this.type = 'eth'
        break
      case '3':
        this.type = 'ltc'
        break
      default:
        this.type = 'btc'
        break
    }
    this.getdetail()
    this.getdata()
    this.getinfo()
    // 请求订单列表
    this.getlist(0)
    this.getlist(1)
    this.tradetimer = setInterval(() => {
      this.getlist(0)
      this.getlist(1)
    }, 5000)
  },
  beforeDestroy() {
    clearInterval(this.tradetimer)
  },
  methods: {
    // 获取订单列表
    async getlist(status) {
      const { data } = await this.$http.get(
        '/home/trade/orderlist/productid/' +
          this.productid +
          '/status/' +
          status
      )
      if (data) {
        if (data.code === 200) {
          if (status === 1) {
            if (data.data.length > this.completedorder.length) {
              for (
                let i = this.completedorder.length;
                i < data.data.length;
                i++
              ) {
                this.completedorder.unshift(data.data[i])
              }
            }
          } else {
            this.progressorder = data.data
          }
        }
      }
    },
    // 获取产品详情
    async getdetail() {
      const { data } = await this.$http.get(
        '/home/trade/getdetail/productid/' + this.productid
      )
      if (data) {
        if (data.code === 200) {
          console.log(data.data.product_title)
          this.gettradeto(data.data.product_code)
          this.detail = data.data
          this.ischecked = data.data.iszx
        } else {
          this.$toast.error(this.$t('common.fail'))
        }
      }
    },
    async gettradeto(productCode) {
      console.log(this.detail)
      const { data } = await this.$http.get(
        '/home/trade/gettradeto/type/' + productCode
      )
      if (data) {
        if (data.code === 200) {
          this.tradeData = data.data[0]
          // this.ischecked = data.data.iszx
        } else {
          this.$toast.error(this.$t('common.fail'))
        }
      }
    },
    async getinfo() {
      const { data } = await this.$http.get('/home/trade/getunitinfo')
      if (data) {
        if (data.code === 200) {
          let that = this
          let units = data.data
          units.forEach(function(value, index, units) {
            if (units[index].unit == that.currycurrent) {
              that.currencyhuilv = units[index].huilv
              that.currencysysm = units[index].sysm
            }
          })
        }
      }
    },
    // 获取行情最新数据
    getdata() {
      this.websocket = new WebSocket(this.wsurl + '/wsss:3052')
      this.websocket.onopen = this.socketonopen
      this.websocket.onmessage = this.socketonmessage
    },
    socketonopen() {
      // this.websocket.send(this.type)
      this.websocket.send(this.productid)
    },
    socketonmessage(evt) {
      this.pagedata = JSON.parse(evt.data)
      this.currentprice = this.pagedata.current
    },
    // 去法币交易市场
    tofabi() {
      this.$router.push('/trade/Foreign')
    },
    // 去理财页面
    tofund() {
      this.$router.push('/fund')
    },
    // 打开货币行情弹出层
    async open() {
      this.isshow = true
      // 获取自选和产品列表
      const { data } = await this.$http.get('/home/trade/getproduct')
      if (data) {
        if (data.code === 200) {
          this.productlist = data.data.productlist
          this.zixuanlist = data.data.zixuanlist
        }
      }
    },
    // 选择不同产品事件
    changeproduct(name, id) {
      this.type = name.toLowerCase()
      this.productid = id
      this.websocket.close()
      this.getdetail()
      // this.gettradeto()
      this.getdata()
      this.isshow = false
    },
    // 添加或取消自选事件
    async changechecked() {
      const { data } = await this.$http.get(
        '/home/trade/zixuan/productid/' +
          this.productid +
          '/istrue/' +
          this.ischecked
      )
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.$t('common.success'))
        } else {
          this.$toast.error(this.$t('common.fail'))
        }
      }
    },
    quanbuye() {
      let ubalance = parseFloat(this.tradedata.balance) * parseFloat(this.currencyhuilv)
      this.tradedata.total = ubalance.toFixed(4)
    },
    // 选择下单时间
    choose(index) {
      this.isactive = index
      this.tradedata.times = this.detail.times[index]
      this.tradedata.ratio = this.detail.ratio[index]
    },
    // 选择下单时间
    choosemoney(index) {
      this.isactives = index
      this.tradedata.total = this.moneys[index]
    },
    // 买空买多弹出层
    async tradeopen(type) {
      // 判断产品下单时间并获取收费费和可用余额
      const { data } = await this.$http.get(
        '/home/trade/befororder/productid/' + this.productid
      )
      if (data) {
        if (data.code === 200) {
          // let feesss = data.data.fee * data.data.balance / 100
          // this.tradedata.fee = feesss.toFixed(4)
          this.feeradio = data.data.fee
          // this.tradedata.fee = data.data.fee
          this.tradedata.balance = data.data.balance
        } else {
          this.$toast.fail(data.msg)
          return false
        }
      }
      this.tradedata.type = type
      this.tradedata.price = this.currentprice
      this.tradedata.productid = this.productid
      this.tradedata.product_title = this.detail.product_title
      this.tradedata.currency = this.currycurrent
      this.tradedata.huilv = this.currencyhuilv
      this.tradeshow = true
    },
    // 验证订单
    beforsubmit() {
      if (!this.tradedata.times) {
        this.$toast.fail(this.$t('trade.selecttime'))
        return false
      }
      if (!this.tradedata.total) {
        this.$toast.fail(this.$t('trade.input'))
        return false
      }
      let minnu = parseFloat(this.detail.minimum[this.isactive]);
      let maxnu = parseFloat(this.detail.maximum[this.isactive]);
      if (
        parseFloat(this.tradedata.total) < minnu ||
        parseFloat(this.tradedata.total) > maxnu
      ) {
        this.$toast.fail(this.$t('trade.error'))
        return false
      }
      console.log(this.tradedata.balance * this.currencyhuilv)
      if (+this.tradedata.total > +this.tradedata.balance * this.currencyhuilv) {
        this.$toast.fail(this.$t('trade.error1'))
        return false
      }
      this.tradedata.fee = this.feeradio * this.tradedata.total / 100
      this.tradestep = 2
      this.submit()
    },
    // 倒计时结束
    finish() {
      this.tradeshow = false
    },
    // 提交订单
    async submit() {
      // this.tradeshow = false
      if (this.tradeLoading) {
        console.log('loading', this.tradeLoading)
        return false
      }
      this.tradeLoading = true
      const { data } = await this.$http.post(
        '/home/trade/order',
        this.tradedata
      )
      if (data) {
        if (data.code === 200) {
          this.$toast.success(this.getlang(data.msg, localStorage.getItem('lang')))
          // this.tradestep = 1
          // this.tradeshow = false
          this.tradeLoading = false
        } else {
          this.$toast.fail(data.msg)
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.header {
  /deep/.van-tab {
    color: grey;
    font-size: 0.9333rem;
  }
  /deep/.van-tab--active {
    color: #004ea3;
  }
}

.maincontent {
  //padding: 44px 0;
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  font-size: 0.93333rem;
  background: #fff;
  // 产品
  .name-icon {
    width: 90%;
    margin: 0.93333rem 0 0.4rem 5%;
    color: #000;
    font-size: 1.33333rem;
    text-align: left;
    display: flex;
    align-items: center;
  }
  // 价格
  .total {
    width: 100%;
    display: flex;
    justify-content: center;
    .head {
      width: 95%;
      padding: 0.8rem 1.2rem 0 1.2rem;
      color: #000;
      border-radius: 0.53333rem;
      line-height: 1.6rem;
      display: flex;
      .head-now {
        flex: 0.5;
        line-height: 1.5rem;
        text-align: left;
        h1 {
          color: #85b480;
          font-size: 1.5rem;
        }
        span {
          font-size: 0.85333rem;
          margin-right: 0.26667rem;
        }
      }
      .head-history {
        display: flex;
        flex: 0.5;
        div {
          text-align: left;
          flex: 0.5;
        }
        .head-extreme span {
          font-size: 0.85333rem;
          color: #b2b2b2;
          margin-right: 0.26667rem;
        }
      }
    }
  }
  // k图
  .total .van-tabs {
    width: 95%;
    font-family: DIN-Medium, serif;
  }
  .total /deep/.van-tab--active {
    color: #004ea3;
  }
  // 订单列表
  .order-List {
    margin-bottom: 4rem;
    /deep/.van-tabs__wrap {
      border-bottom: 0.02667rem solid #e5e5e5;
    }
    .currency-Increase {
      color: #000;
      padding: 0.8rem 1.33333rem 0.8rem 1.33333rem;
      font-size: 1.6rem;
      display: flex;
      align-items: center;
      line-height: 1.6rem;
      border-bottom: 0.02667rem solid #e5e5e5;
    }
    .currency-time {
      font-size: 0.8rem;
      color: grey;
      text-align: right;
    }
    // 简介
    .brief {
      padding: 1.06667rem;
      text-align: left;
      .brief-publish {
        padding-left: 0.53333rem;
        line-height: 2.93333rem;
        border-bottom: 0.02667rem solid #e5e5e5;
        h3 {
          color: #212121;
          font-size: 1rem;
        }
      }
      span {
        font-size: 0.93333rem;
        color: grey;
      }
    }
  }
  // 操作组
  .operation {
    width: 100%;
    height: 3.2rem;
    background-color: #fff;
    position: fixed;
    bottom: 2.8rem;
    display: flex;
    align-items: center;
    border-top: 0.02667rem solid #e5e5e5;
    z-index: 999;
    .van-button {
      width: 80%;
      height: 1.86667rem;
    }
  }
  // 弹出层
  .personage-head {
    height: 8%;
    background-color: #fff !important;
    color: #000;
    display: flex;
    align-items: center;
    justify-content: center;
    /deep/.van-tab {
      color: grey;
      font-size: 0.9333rem;
    }
    /deep/.van-tab--active {
      color: #004ea3;
    }
    h3 {
      font-size: 1.3333rem;
    }
  }
  .popup-Increase {
    background-color: #f1f1f1;
    color: #000;
    padding: 0.8rem 1.33333rem 0.8rem 1.33333rem;
    border-bottom: 0.02667rem solid #fff;
    font-size: 0.93333rem;
  }
  // 交易弹出层
  .operation-buyUp {
    width: 85%;
    border-radius: 0.4rem;
    background-color: #fff !important;
    color: #fff !important;
    overflow: hidden;
    span {
      color: #000;
    }
    h3 {
      padding-top: 4%;
      padding-bottom: 4%;
      display: flex;
      align-items: center;
      justify-content: center;
      border-bottom: 0.02667rem solid #fff;
      font-weight: 400;
      color: #000;
      font-size: 1rem;
    }
    .text {
      height: 90%;
      .van-cell {
        font-size: 0.75333rem;
        height: 2rem;
        padding: 0.26667rem 0.8rem 0.26667rem 0.8rem;
        background-color: #fff !important;
      }
      .van-cell:after {
        border-bottom: 0;
      }
      .van-cell__title {
        text-align: left;
      }
      .currency-information {
        margin: 0.8rem 1.33333rem 1.33333rem 1.06667rem;
        line-height: 1.15rem;
        font-size: 0.93333rem;
        display: flex;
        white-space: nowrap;
        overflow-x: auto;
        .information {
          flex-grow: 1;
          flex-shrink: 0;
          margin-right: 0.2rem;
          width: 4rem;
          height: 4rem;
          background-color: #424c66;
          padding-top: 0.18rem;
          color: #094;
          border-top-left-radius: 0.26667rem;
          border-top-right-radius: 0.26667rem;
        }
        .bg {
          background-color: #004ea3;
          color: #fff;
        }
      }
      .money-information {
        margin: 0.8rem 1.33333rem 1.33333rem 1.06667rem;
        line-height: 1.5rem;
        font-size: 0.93333rem;
        display: flex;
        white-space: nowrap;
        overflow-x: auto;
        .moneynums {
          flex-grow: 1;
          flex-shrink: 0;
          margin-right: 0.2rem;
          width: 3rem;
          height: 1.5rem;
          background-color: #424c66;
          //padding-top: 0.18rem;
          color: #094;
          border-radius: 0.26667rem;
        }
        .bgs {
          background-color: #12ced4;
          color: #fff;
        }
      }
      /deep/.van-field__control {
        font-size: 1rem !important;
      }
      .count-down {
        margin-top: 0.53333rem;
        width: 8rem;
        height: 8rem;
        line-height: 8rem;
        background-color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 4rem;
        color: #d90012;
        font-size: 2rem;
        border: 0.1667rem solid #d90012;
      }
    }

    .qbye {
      width: 65%;
      margin-left: 10%;
    }
    .van-button {
      width: 100%;
      height: 10%;
      margin: 0;
      padding-top: 4%;
      padding-bottom: 4%;
      letter-spacing: 0.53333rem;
      color: #fff;
      background-color: #004ea3;
      border: none;
      border-top: 0.02667rem solid #fff;
      font-size: 1.06667rem;
      .van-button__text {
        color: #fff;
      }
    }
  }
}
</style>
